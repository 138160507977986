<template>
    <cmpRec v-bind:name="name"
            v-bind:pkColumn="pkColumn"
            v-bind:fields="fields"
            v-bind:parent="parent"
            v-bind:header="header"></cmpRec>
</template>

<script>
    import i18n from '@/i18n'
    import cmpRec from "@/components/common/cmpRec"

    export default {
        components: {
            cmpRec
        },
        computed: {
            fields() {
                return [
                    { "key": "MemberId", "label": i18n.t('component.User.MemberId.label') },
                    { "key": "Account", "label": i18n.t('component.User.Account.label') },
                    { "key": "Name", "label": i18n.t('component.User.Name.label') },
                    { "key": "Code", "label": i18n.t('component.User.Code.label') },
                    { "key": "Title", "label": i18n.t('component.User.Title.label') },
                    { "key": "Birthday", "label": i18n.t('component.User.Birthday.label'), input: { type: "datetime" } },
                    { "key": "GenderName", "label": i18n.t('component.User.GenderName.label') },
                    { "key": "VideoUrl", "label": i18n.t('component.User.VideoUrl.label') },
                    { "key": "Email", "label": i18n.t('component.User.Email.label') },
                    { "key": "EthnicName", "label": i18n.t('component.User.EthnicName.label') },
                    { "key": "UnitName", "label": i18n.t('component.User.UnitName.label') },
                    { "key": "FatherName", "label": i18n.t('component.User.FatherName.label') },
                    { "key": "MotherName", "label": i18n.t('component.User.MotherName.label') },
                    { "key": "CountryCode", "label": i18n.t('component.User.CountryCode.label') },
                    { "key": "ZipCodeName", "label": i18n.t('component.User.ZipCodeName.label') },
                    { "key": "Address", "label": i18n.t('component.User.Address.label') },
                    { "key": "ZipCode1Name", "label": i18n.t('component.User.ZipCode1Name.label') },
                    { "key": "Address1", "label": i18n.t('component.User.Address1.label') },
                    { "key": "ProjectRoleName", "label": i18n.t('component.User.ProjectRoleName.label') },
                    { "key": "PhoneNumber1", "label": i18n.t('component.User.PhoneNumber1.label') },
                    { "key": "PhoneNumber2", "label": i18n.t('component.User.PhoneNumber2.label') },
                    { "key": "PhoneNumber", "label": i18n.t('component.User.PhoneNumber.label') },
                    { "key": "BankCode", "label": i18n.t('component.User.BankCode.label') },
                    { "key": "BankAccount", "label": i18n.t('component.User.BankAccount.label') },
                    // { "key": "ProjectCode", "label": "專案代號" },
                    { "key": "Memo", "label": i18n.t('component.User.Memo.label') }
                ]
            },
            header() {
                return i18n.t('component.User.recHeader')
            }
        },
        data() {

            return {
                name: "User",
                pkColumn: "UserId",
                parent: {}
            };
        }
    }
</script>